* 
	padding: 0px
	margin: 0px
	border: none


*,
*::before,
*::after 
	box-sizing: border-box




/* Links */

a, a:link, a:visited  
	text-decoration: none


a:hover
	/* color: inherit; */
	text-decoration: none


/* Common */

ul,li
	list-style:  none

h1, h2, h3, h4, h5, h6, p
	font-size: inherit
	font-weight: inherit




img 
	vertical-align: top


img, svg 
	max-width: 100%
	height: auto


address 
	font-style: normal


/* Form */

input, textarea, button, select
	font-family: inherit
	font-size: inherit
	color: inherit
	background-color: transparent

input::-ms-clea
	display: non

button, input[type="submit"]
	box-shadow: none
	background-color: transparent
	background: none
	cursor: pointer

input:focus, input:active,
button:focus, button:active 
	outline: none

button::-moz-focus-inner 
	padding: 0
	border: 0


label 
	cursor: pointer


legend 
	display: block

