@import variable
@keyframes shake
    0% 
        transform: translate(0, 0)
    25% 
        transform: translate(2px, -2px)
    50% 
        transform: translate(-2px, 2px)
    75% 
        transform: translate(2px, 2px)
    100% 
        transform: translate(-2px, -2px)
html[data-theme='dark']
    --bac-color: #181818
    --main-color: #202020
    --sub-color: #303030
    --activeGreen-color: #15ab1a
    --activeGray-color: #928D8D
    --activeLastUpdate-color: #928D8D
    --text-color: #fff
    --title: #fff
    --borderLastUpdate: #4F4F4F
    --itemLastUpdate: #333333       
    --hoverLastUpdate: #4A4A4A
    --Main-block: #202020
    --Main-block-text: #fff
    --LineUpdate: #D9D9D9
    --ProfileActive: #272727
    --ProfileImage: #181818
    --Pagination: #252525
    --PaginationDisabled: #404040
    --PaginationDisabled2: #252525

html[data-theme='light']
    --bac-color: #ffffff
    --main-color: #c3d9e7
    --sub-color: #FFF
    --activeGreen-color: #1d87e1
    --activeGray-color: #55616b
    --activeLastUpdate-color: #1d87e1
    --text-color: #000
    --title: #fff
    --borderLastUpdate: #7b7272
    --itemLastUpdate: #bfbbbb
    --hoverLastUpdate: #8f8787
    --Main-block: #5e93b5
    --Main-block-text: #fff
    --LineUpdate: #282828
    --ProfileActive: #a6aaae
    --ProfileImage: #c0c0c0
    --Pagination: #878787
    --PaginationDisabled: #ffffff
    --PaginationDisabled2: #eae8e8
*
    user-select: none
*
    .container 
        width: 1146px
        margin: 0 auto
#root
    height: 100%
//
html, html img
    -webkit-font-smoothing: antialiased
html,
body
    height: 100%
    
.wrap
    display: flex
    flex-direction: column
    justify-content: space-between
    position: relative
    min-height: 100%
//

body
    background-color: var(--bac-color)
    transition: all .5s ease

header
    //top: -70px
    position: fixed
    width: 100%
    background-color: var(--Main-block)
    z-index: 10
    transition: all .5s ease
    .nav
        height: 70px
        display: flex
        justify-content: space-between
        align-items: center
        .wrapper
            display: flex
            align-items: center
            gap: 20px
        .login
            display: flex
            margin-left: 20px 
            width: 100%
            padding: 5px
            background-color: #2d73e4
            border-radius: 10px
            cursor: pointer 
            //justify-content: flex-end
            align-items: flex-end
            transition: all .3s ease
            &:hover
                transform: scale(1.1)
                background-color: #2157af
            &_img
                width: 25px
                height: 25px
                position: relative
                top: -2px
                svg
                    width: 25px
                    height: 25px
                    color: #fff
            &_text
                color: var(--title)
                font-weight: 300
                font-size: 18px
        .searh
            &_form
                position: relative
                width: 350px
                input
                    width: 100%
                    height: 27px
                    outline: none
                    border: none
                    border-radius: 3px
                    padding: 0
                    padding-left: 30px
                    background: var(--sub-color)
                    color: var(--text-color)
                    transition: all .5s ease
                .submit
                    position: absolute
                    height: 27px
                    padding: 2px
                    outline: none
                    border: none
                    border-radius:10px 
                    cursor: pointer
                .clear
                    width: 20px
                    height: 1px
                    top: 13px
                    right: 10px
                    position: absolute
                    transform: rotate(-45deg)
                    background-color: var(--activeGray-color)
                    &::before
                        content: ""
                        position: absolute
                        width: 20px
                        height: 1px
                        background-color: var(--activeGray-color)
                        transform: rotate(90deg)
                        cursor: pointer
                         
                .autocomplete
                    position: absolute
                    width: inherit
                    max-height: 350px
                    height: auto
                    top: 35px
                    left: 0px
                    padding-left: 27px
                    background: var(--sub-color)
                    // box-shadow: 0 0 10px #404040
                    // border: 1px solid #404040
                    overflow: auto
                    border-radius: 3px
                    
                    li
                        margin: 0
                    &_item
                        display: flex
                        gap: 10px
                        padding: 10px
                        &_img
                            width: 70px
                            height: 98px
                            border-radius: 10px
                        &_text
                            display: flex
                            flex-direction: column
                            justify-content: center

                            .name
                                color: var(--text-color)
                                font-weight: 300
                                font-size: 14px
                            .subname
                                color: var(--activeGray-color)
                                font-weight: 300
                                font-size: 14px
        .switchTheme
            padding: 3px
            position: relative
            display: flex
            justify-content: space-between
            background-color: #000
            width: 62px
            height: 33px
            border-radius: 20px
            cursor: pointer
            
            .dote
                position: absolute
                top: 3px
                left: 3px
                
                &::before
                    content: ""
                    position: absolute
                    width: 27px
                    height: 27px
                    background-color: #fff
                    border-radius: 50%
                            
            &_item
                display: flex
                justify-content: center
                align-items: center
                width: 27px
                height: 27px
                border-radius: 10px
                transition: all .5s ease  
            .active + .dote::before
                    transition: all .5s ease  
                    transform: translateX(29px)  
            .disabled + .dote::before
                    transition: all .5s ease  
                    //display: none
                    transform: translateX(0px)  


        .dropdown-profile
            position: relative
            display: inline-block
            &_counter
                position: absolute
                display: flex
                justify-content: center
                align-items: center
                top: -5px
                right: -5px
                color: var(--Main-block-text)
                font-weight: 300
                font-size: 15px
                width: 20px
                height: 20px
                background-color: red
                border-radius: 10px

        .dropdown-content
            padding: 17px
            top: 65px
            //right: 50%
            //transform: translateX(50%)
            right: 0px
            border-radius: 10px
            display: block
            min-width: 170px
            position: absolute
            z-index: 1
            background: var(--main-color)
            text-align: start
            transition: all .2s ease
            //transform: scale(0)
            border: 1px solid #404040
            box-shadow: 0 0 10px #404040
            &_show
                transform: scale(1)
            .line
                display: block
                margin: 0 auto
                width: 150  px
                height: 1px
                background: #565555
                padding: 0
                margin-top: 10px
                margin-bottom: 10px
            &__item
                padding: 8px 0 8px 5px
                display: block
                font-weight: 300
                font-size: 15px
                line-height: 18px
                color: var(--text-color)
                border-radius: 5px
                transition: all .2s ease
                
                span
                    color: red
                svg
                    width: 20px
                    height: 20px
                    position: relative
                    top: 3px
                    margin-right: 5px
                    color: var(--text-color)
                &:hover
                    background: var(--sub-color) 
                &:active
                    transform: scale(1.1)
            .btn
                svg
                    color: red
                

        .logo
            width: 50px
            height: 60px
        .profile
            height: 50px
            width: 50px
        .menu
            display: flex
            &_link
                position: relative
                text-decoration: none
                font-weight: 400
                font-size: 20px
                line-height: 23px
                color: var(--Main-block-text)
                transition: all .2s ease
                img
                    position: relative
                &:after 
                    content: ""
                    display: block
                    position: absolute
                    right: 0
                    bottom: -3px
                    width: 0
                    height: 2px
                    background-color: var(--activeGray-color)
                    transition: width 1s 
                &:hover:after 
                    content: ""
                    width: 100%
                    display: block
                    position: absolute
                    left: 0
                    bottom: -3px
                    height: 2px
                    background-color: var(--Main-block-text)
                    transition: width 0.5s
                
                
                
                    
        li:nth-child(2)
            margin: 0 33px 0 33px 

.fixed
    position: fixed
    transform: translateY(70px)


.intro
    margin-top: 70px
    width: 100%
    min-height: 300px
    background: url('../assets/img/introblock.jpg') center no-repeat 
    &_title
        padding: 169px 0 169px 0
        text-align: center
        font-weight: 700
        font-size: 48px
        line-height: 56px
        color: var(--title)

.continueRead
    display: none
    padding: 35px 0 35px
    &_active
        display: block
    &_block
        background-color: var(--main-color)
        width: 100%
        min-height: 376px
        border-radius: 35px
        transition: all .5s ease
        .title
            padding-left: 70px
            font-weight: 300
            font-size: 32px
            line-height: 38px
            color: var(--text-color)
            transition: all .5s ease
        .contineSlider
            position: relative
            padding: 34px 30px 34px 30px
            text-align: center
            width: 100%
            display: block
            .book
                display: block
                //margin-left: 27px
                min-height: 265px
                width: 155px
                &:first-child
                    margin-left: 0px
                &_img
                    img
                        width: 155px
                        height: 218px
                        border-radius: 10px
                &_name
                    font-weight: 300
                    width: 155px
                    margin: 0 auto
                    font-size: 15px
                    line-height: 27px
                    color: var(--text-color)
                    word-wrap: normal   
                    transition: all .2s ease
                    &:hover
                        color: var(--activeLastUpdate-color)
                &_subName
                    font-weight: 400
                    font-size: 12px
                    line-height: 16px
                    color: var(--activeGray-color)
                    
                &:focus
                    outline: none
            .slick-arrow
                background: var(--text-color) 
                position: absolute
                top: 50%
                transform: translateY(-50%)
                z-index: 5
                font-size: 0
                width: 20px
                height: 30px
                &.slick-prev
                    margin-left: 5px 
                    left: 0
                    background: url('../assets/icons/left-min.svg') 0 0 no-repeat
                &.slick-next
                    margin-right: 5px 
                    right: 0
                    background: url('../assets/icons/right-min.svg') 0 0 no-repeat
            .slick-track
                display: flex
            .slick-list
                overflow: hidden
            &:focus
                outline: none
            


.slider
    display: block
    width: 100%
    min-height: 380px
    background-color:var(--main-color)
    padding: 37px 0px 37px 0px   
    &_book
        display: block
        min-height: 258px
        width: 174px
        &_img
            img
                width: 174px
                height: 258px
                border-radius: 10px

        &_name
            position: relative
            font-weight: 300
            font-size: 17px
            line-height: 28px
            color: var(--text-color)
            transition: all .2s ease
            word-wrap: break-word
                
        &_subName
            font-weight: 400
            font-size: 14px
            line-height: 16px
            color: var(--activeLastUpdate-color)
        
        &:focus 
            outline: none
        &:hover
            .slider_book_name
                color: var(--activeLastUpdate-color)
                       
//////////////////стили слайдера/////////
.slick-track
    display: flex
.slick-list
    overflow: hidden
/////////////////////////////////////////
.lastUpdate
    display: block
    margin-top: 25px
    &__title
        display: flex
        justify-content: space-between
        align-items: center
        margin-bottom: 20px
        .title
            font-weight: 400
            font-size: 24px
            line-height: 28px
            color: var(--text-color)
        
    .line
        width: 388px
        height: 1px
        background-color:  var(--LineUpdate)


    .book
        margin-bottom: 20px
        .book-item
            border-radius: 10px
            padding: 10px
            position: relative
            margin: 0 auto
            background-color: var(--main-color) 
            width: 820px
            min-height: 358px
            transition: all .5s ease
            box-shadow: 0 0 10px #404040
            border: 1px solid #404040
            &__top
                display: flex
                gap: 20px
            &__left
                img
                    width: 222px
                    height: 296px
                    border-radius: 10px
            &__right
                .right-title
                    margin-right: 50px
                    &_name
                        font-weight: 300
                        font-size: 24px
                        line-height: 33px
                        color: var(--text-color)
                        transition: all .2s ease
                        &:hover
                            color: var(--activeLastUpdate-color)
                    &_subName
                        word-break: break-all   
                        font-size: 18px
                        color: var(--activeLastUpdate-color)

                    
                .right-description
                    width: 557px
                    //word-break: break-all
                    margin-top: 20px
                    font-weight: 300
                    font-size: 16px
                    line-height: 22px
                    color: var(--text-color)
                    text-align: justify
                    transition: all .5s ease
            .rating
                display: flex
                position: absolute
                top: 14px
                right: 10px
                .star
                    position: relative
                    top:2px
                    width: 20px
                    height: 20px
                .rating-text
                    margin-left: 5px
                    font-weight: 300
                    font-size: 20px
                    line-height: 27px
                    color: var(--text-color)
                    transition: all .5s ease
            &__bottom
                display: flex
                align-items: center
                justify-content: space-between
                margin-top: 10px
                height: 31px
                background: var(--itemLastUpdate)
                border: 1px solid var(--borderLastUpdate)
                border-radius: 30px
                transition: all .5s ease
                .read
                    display: flex
                    align-items: center
                    justify-content: center
                    width: 222px
                    height: 31px
                    border: 1px solid var(--borderLastUpdate)
                    border-radius: 30px
                    font-weight: 700
                    font-size: 16px
                    line-height: 19px
                    color: var(--text-color)
                    transition: all .4s ease
                    &:hover
                        //scale: 1.05
                        background: var(--hoverLastUpdate)
                .mark
                    display: flex
                    justify-content: center
                    align-items: center
                    background: #3384da
                    border-radius: 30px
                    width: 138px
                    height: 31px
                    font-weight: 300
                    font-size: 16px
                    line-height: 22px
                    color: var(--text-color)
                    img
                        width: 14px
                        height: 14px
                        margin-right: 5px
                .book-dropdown
                    position: relative
                    display: block
                .dropdown-ul
                    display: block
                    width: 138px
                    top: 36px
                    z-index: 1
                    position: absolute
                    background: var(--sub-color)
                    padding: 10px
                    right: 50%
                    transform: translateX(50%)
                    border-radius: 10px
                    &__item
                        padding: 5px 5px
                        font-weight: 300
                        font-size: 16px
                        line-height: 19px
                        color: #FBF8F8
                        border-radius: 2px
                        transition: all .2s ease
                        li
                            cursor: pointer
                        &:hover
                            background: var(--borderLastUpdate)

                        &_active
                            color: #3384da
                            background-color: var(--borderLastUpdate)
    // .book:not(:nth-child(-n + 6))
    //     display: none
    .more
        display: flex
        justify-content: center
        align-items: center  
        width: 200px
        height: 42px
        margin: 20px auto 20px auto
        background: #252525
        border-radius: 30px
        font-weight: 300
        font-size: 20px
        line-height: 23px
        color: var(--text-color)
        transition: all .2s ease
        &:hover
            background: var(--itemLastUpdate)
        &:active
            transition: all .2s ease
            scale: 1.05



.footer
    // position: absolute
    // left: 0
    // bottom: 0
    width: 100%
    padding: 71px 0 71px 0
    height: 300px
    background-color: var(--Main-block)
    transition: all .5s ease
    .wrapper
        display: flex
        justify-content: space-between
    .text
        font-weight: 300
        font-size: 16px
        line-height: 19px
        color: var(--Main-block-text)
        transition: all .5s ease
    .logo-title
        padding: 10px 0 30px 0
        font-weight: 300
        font-size: 16px
        line-height: 19px
        color: var(--Main-block-text)
        transition: all .5s ease
    .logo
        display: flex
        align-items: center
        &__text
            margin-left: 20px
            font-weight: 300
            font-size: 40px
            line-height: 47px
            color: var(--Main-block-text)
            transition: all .5s ease
            span
                font-weight: 700
    .menu-footer
        &__link
            font-weight: 700
            font-size: 17px
            line-height: 20px
            color: var(--Main-block-text)
            transition: all .2s ease
            &:hover
                color: var(--activeGray-color)
        li:nth-child(2)
            margin: 18px 0 18px 0
           
    .list  
        &__link
            font-weight: 700
            font-size: 17px
            line-height: 20px
            color: var(--Main-block-text)
            text-decoration: none
            transition: all .2s ease
            &:hover
                color: var(--activeGray-color)
            img
                margin-right: 5px
                position: relative
                top:3px
        li:nth-child(2)
            margin: 10px 0 10px 0
    .contacts
        &__title
            font-weight: 700
            font-size: 17px
            line-height: 20px
            color: var(--Main-block-text)
            transition: all .5s ease
        &__text
            padding: 25px 0 12px 0
            font-weight: 300
            font-size: 16px
            line-height: 25px
            color: var(--Main-block-text)
            transition: all .5s ease
        &__social
            display: flex
            gap: 15px
            img
                transition: all .5s ease
                &:hover
                    scale: 1.3





.profile
    //margin-top: 35px
    margin-top: 105px
    padding-bottom: 22%
    
    .wrapper
        
        display: flex
        gap: 10px
        flex-wrap: wrap
    &_image
        width: 200px
        height: 200px
        display: block
        background-color: var(--ProfileImage)
        border-radius: 50%
        transition: all .5s ease
        //margin-right: 20px
    &_intro
        display: block
        img
            width: 936px
            height: 200px
            border-radius: 10px 
    &_nickName
        display: flex
        align-items: center
        width: 200px
        min-height: 35px
        background-color: var(--main-color)
        border-radius: 10px
        transition: all .5s ease
        .nick
            text-align: center
            width: 200px
            word-break: break-all
            font-weight: 300
            font-size: 20px
            color: var(--text-color)
            transition: all .5s ease
    &_navBar
        padding-left: 25px
        display: flex
        gap: 25px
        width: 936px
        height: 35px
        background-color: var(--main-color)
        border-radius: 10px
        transition: all .5s ease
        .navBar_link
            padding: 5px 10px 5px 10px
            display: flex
            align-items: center
            font-weight: 300
            font-size: 16px
            line-height: 19px
            color: var(--text-color)
            border-radius: 10px
            transition: all .3s ease
            &:hover
                background-color: var(--sub-color)
            &:active
                transform: scale(1.1)
        .disabled
            color: var(--activeGray-color)
            pointer-events: none
    &_list
        width: 200px
        height: 100%
        padding: 20px 10px 20px 10px
        background-color: var(--main-color)
        border-radius: 10px
        transition: all .5s ease
        .item
            padding: 7px 0 7px 10px
            border-radius:3px
            transition: all .2s ease
            font-weight: 300
            font-size: 18px
            line-height: 21px
            color: var(--text-color)
            cursor: pointer
            user-select: none
            &_active
                background-color: var(--ProfileActive)
                transform: translate(-20%)
                
            &:hover
                background-color: var(--sub-color)
            &:active
                //transform: scale(1.1)
                transform: translateX(0%)
            &:focus
                outline: none
                text-decoration: none
    

    .content
        width: 936px
        &_clear
            height: 100%
            display: flex
            justify-content: center
            aligns-items: center
            color: var(--text-color)
            flex-direction: column
            gap: 10px
            transition: all .5s ease
            .title
                font-weight: 400
                font-size: 20px
                display: flex
                justify-content: center
            .description
                font-weight: 300
                font-size: 16px
                display: flex
                justify-content: center
            .image
                margin: 0 auto
                width: 150px
                height: 150px
                opacity: 0.8
            .link
                display: flex
                gap: 50px
                justify-content: center
                .redirect
                    color: var(--text-color)
                    width: 100px
                    height: 30px
                    display: flex
                    justify-content: center
                    align-items: center
                    background-color: var(--sub-color)
                    border-radius: 3px
                    transition: all .2s ease
                    &:hover
                        background-color: var(--main-color)
        &_item
            position: relative
            display: flex
            padding: 10px
            margin-bottom: 10px
            width: 100%
            min-height: 128px
            border-radius: 15px
            background-color: var(--main-color)
            transition: all .5s ease
            .img
                display: inline-block
                img
                    border-radius: 10px 
            .text
                padding: 10px 0 0 10px
                .name
                    font-weight: 300
                    font-size: 16px
                    line-height: 19px
                    color: var(--text-color)
                    transition: all .2s ease
                    &:hover
                        color: var(--activeGray-color)
                .subName
                    margin-top: 5px
                    font-weight: 300
                    font-size: 14px
                    line-height: 16px
                    color: var(--activeGray-color)
                    transition: all .5s ease
                .continue
                    margin-top: 15px
                    font-weight: 300
                    font-size: 14px
                    line-height: 16px
                    color: var(--activeGray-color)
                    transition: all .2s ease
                    &:hover
                        color: var(--text-color)
                        
                    &:active
                        transform: scale(1.1)
            .Delete
                position: absolute
                top: 50%
                transform: translateY(-50%)
                right: 30px
                transition: all .5s ease
                border-radius: 50%
                &:hover
                    background-color: var(--activeGray-color) 
                img
                    width: 40px
                    height: 40px
            img
                width: 80px
                height: 108px
                


.catalog
    //margin-top: 35px
    min-height: 860px
    margin-top: 105px
    padding-bottom: 100px 
    .wrapper
        position: relative
        display: flex
        gap: 10px
        flex-wrap: wrap
    &_head
        position: relative
        display: flex
        justify-content: space-between
        align-items: center
        padding: 0 15px 0 15px
        width: 843px
        height: 52px
        background-color: var(--main-color)
        border-radius: 10px
        // box-shadow: 0 0 10px #404040
        // border: 1px solid #404040
        transition: all .5s ease
    .searh
        &_form
            position: relative
            width: 300px
            input
                width: 100%
                height: 35px
                outline: none
                border: none
                border-radius: 3px
                padding: 0
                padding-left: 20px
                background: var(--sub-color)
                color: var(--text-color)
                font-weight: 300
                font-size: 16px
                transition: all .5s ease
            .clear
                width: 20px
                height: 1px
                top: 17px
                right: 10px
                position: absolute
                transform: rotate(-45deg)
                background-color: var(--activeGray-color)
                &::before
                    content: ""
                    position: absolute
                    width: 20px
                    height: 1px
                    background-color: var(--activeGray-color)
                    transform: rotate(90deg)
    .title
        border-radius: 10px
        font-weight: 300
        font-size: 20px
        color: var(--text-color)
        transition: all .5s ease
    &_sort
        display: flex
        gap: 15px
        .text
            padding: 5px
            display: flex
            font-weight: 300
            font-size: 16px
            line-height: 19px
            color: var(--text-color)
            transition: all .5s ease
        .btn_drop
            position: relative
            top:1px
            font-weight: 300
            font-size: 16px
            line-height: 16px
            color: var(--activeGreen-color)
            transition: all .5s ease
    &_drop
        position: absolute
        gap: 10px
        padding: 10px
        right: 15px
        top: 52px
        background-color: var(--main-color)
        border: 1px solid var(--sub-color)
        box-shadow: 0 0 10px var(--sub-color)
        border-radius: 10px
        z-index: 1
        transition: all .5s ease
        &_item
            padding: 10px
            font-weight: 300
            font-size: 17px
            line-height: 16px
            color: var(--text-color)
            border-radius: 5px
            transition: all .2s ease
            cursor: pointer
            &:hover
                background-color: var(--sub-color)
            &:active
                transform: scale(1.1)
        .active
            color: var(--activeGreen-color)
        
        
    .content
        display: flex
        flex-wrap: wrap
        gap: 12px
        padding: 10px
        width: 843px
        background-color: var(--main-color)
        border-radius: 10px
        // box-shadow: 0 0 10px #404040
        // border: 1px solid #404040
        transition: all .5s ease
        .book
            position: relative
            width: 155px
            height: 218px
            cursor: pointer
            img
                width: 155px
                height: 218px
                border-radius: 10px 
            &_title
                position: absolute
                padding: 5px 10px 10px 10px
                left: 0px
                bottom: 0px
                width: 100%
                min-height: 56px
                background-color: rgba(0, 0, 0, 0.5)
                border-radius: 0 0 10px 10px
                &:hover
                    .tooltipText
                        visibility: visible
                        opacity: 1
            &_name
                position: relative
                font-weight: 300
                font-size: 14px
                line-height: 100%
                color: var(--title)
                overflow: hidden
                white-space: nowrap

            .tooltipText
                //visibility: hidden
                
                padding: 5px
                background-color: #ebdede
                color: var(--text-color)
                position: absolute
                top:-25px
                left: 10px
                z-index: 1
                border-radius: 5px
                /////////текст
                margin-top: 5px
                font-weight: 300
                font-size: 12px
                line-height: 100%
                color: #252627
                white-space: nowrap
                opacity: 0
                transition: opacity 0.3s
                transition: all .5s ease
            



            &_subname
                margin-top: 5px
                font-weight: 300
                font-size: 11px
                line-height: 100%
                color: #7faabf

                
    .sortBar
        padding: 10px 
        position: absolute
        right: 0
        top: 0
        width: 293px
        background-color: var(--main-color)
        border-radius: 10px
        // box-shadow: 0 0 10px #404040
        // border: 1px solid #404040
        transition: all .5s ease
        &_item
            padding: 0 20px 0 20px 
            display: flex
            flex-direction: column
            border-radius: 10px
            transition: all .4s ease
            .apper
                display: none
            .check
                display: flex
                flex-wrap: wrap
                justify-content: space-between
                
                padding: 0 0 5px 0
                &_item
                    padding: 5px
                    border-radius: 5px
                    margin-bottom: 10px
                    font-weight: 300
                    font-size: 16px
                    line-height: 19px
                    color: var(--text-color)
                    cursor: pointer
                    transition: all .5s ease
                    &_active
                        background-color: var(--sub-color)
                        color: var(--activeGreen-color)
                label
                    padding-left: 5px
                    font-weight: 300
                    font-size: 16px
                    line-height: 19px
                    color: var(--text-color)

        .text
            padding: 5px 0 5px 0
            display: flex
            align-items: center
            font-weight: 300
            font-size: 20px
            line-height: 19px
            color: var(--text-color)
            transform: translateX(-10px)

.popular
    //margin-top: 35px
    margin-top: 105px
    padding-bottom: 310px
    &_sort
        justify-content: center
        display: flex
        gap: 30px
        .title 
            font-weight: 300
            font-size: 24px
            line-height: 28px
            color: var(--text-color)
        .btn
            height: 33px
            width: 130px
            display: flex
            justify-content: center
            align-items: center
            background-color: var(--sub-color)
            border-radius: 8px
            gap: 11px
            .text
                font-weight: 300
                font-size: 16px
                line-height: 19px
                color: var(--text-color)
            img
                height: 24px
                width: 24px
    &_catwalk
        padding: 54px 0 50px 0
        .wrapper
            display: flex
            justify-content: center
            gap: 20px
            &:nth-child(1)
                align-items: flex-end
        .marker
            position: absolute
            top: 12px
            left: 12px
            background-color: #a62e2e
            width: 70px
            height: 70px
            padding: 4px
            border-radius: 10px 10px 0px 10px
            &:hover
                animation: shake 0.5s cubic-bezier(0.36, 0.07, 0.19, 0.97) infinite
            img
                 &:hover
                    animation: shake 0.5s cubic-bezier(0.36, 0.07, 0.19, 0.97) infinite
        .name
            font-weight: 300
            font-size: 20px
            line-height: 23px
            color: var(--text-color)
            transition: all .3s ease
            &:hover
                color: var(--activeGray-color) 
                
        .subName
            font-weight: 400
            font-size: 20px
            line-height: 23px
            color: var(--activeGray-color)

        .position
            position: relative
            padding: 28px 32px 10px 32px
            background-color: var(--main-color)
            border-radius: 15px
            &:hover .marker 
                animation: shake 0.5s cubic-bezier(0.36, 0.07, 0.19, 0.97) infinite
            
        .Two
            min-height: 364px
            .img
                padding-bottom: 5px
                width: 186px
                height: 276px
        .One
            min-height: 418px
            .img
                padding-bottom: 5px
                width: 206px
                height: 330px
        .Three
            min-height: 316px
            .img
                padding-bottom: 5px
                width: 147px
                height: 228px
    
    &_item
        margin: 0 auto
        width: 770px
        display: flex
        padding: 8px 0 8px 0
        background-color: var(--main-color)
        border-radius: 15px
        margin-bottom: 20px
        .number
            display: flex
            align-items: center
            padding: 0 24px 0 24px
            font-weight: 400
            font-size: 40px
            line-height: 47px
            color: var(--text-color)
        img
            width: 131px
            height: 178px
        .descr
            padding: 36px 0 0 15px
            .name
                font-weight: 300
                font-size: 20px
                line-height: 23px
                color: var(--text-color)
                transition: all .3s ease
                &:hover
                    color: var(--activeGray-color) 
            .subName
                font-weight: 400
                font-size: 20px
                line-height: 23px
                color: var(--activeGray-color)

.subscription
    padding-top:70px 
    margin: 35px 0 35px 0
    .tarif
        background-color: var(--main-color)
        padding: 20px 29px 35px 29px
        border-radius: 15px
        box-shadow: 0 0 10px #404040
        border: 1px solid #404040
        transition: all .5s ease
        .wrapper
            display: flex
            justify-content: space-between
        &_title
            font-weight: 800
            font-size: 33px
            color: var(--text-color)
            transition: all .5s ease
        &_subtitle
            margin-top: 18px
            font-weight: 300
            font-size: 17px
            color: var(--text-color)
            transition: all .5s ease
        &_item
            min-height: 512px
            width: 327px
            text-align: center
            margin-top: 75px
            border-radius: 10px
            padding: 24px 12px 41px 12px
            
            &:nth-child(1)
                background: linear-gradient(145.31deg, rgba(159, 71, 21, 0.8) 25.6%, rgba(255, 118, 74, 0.8) 43.3%, rgba(253, 103, 19, 0.8) 64.31%, rgba(243, 143, 50, 0.8) 75.76%, rgba(244, 141, 46, 0.8) 81.49%, rgba(244, 146, 75, 0.8) 85.89%)
            &:nth-child(2)
                background: linear-gradient(0deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)), linear-gradient(145.31deg, rgba(248, 248, 248, 0.8) 25.6%, rgba(227, 227, 227, 0.8) 51.03%, rgba(222, 222, 222, 0.8) 64.31%, rgba(186, 180, 180, 0.8) 75.76%, rgba(251, 243, 243, 0.8) 81.49%, rgba(235, 235, 235, 0.8) 85.89%)
            &:nth-child(3)
                background: linear-gradient(0deg, rgba(255, 179, 24, 0.8), rgba(255, 179, 24, 0.8)), linear-gradient(145.31deg, rgba(255, 255, 255, 0.8) 25.6%, rgba(251, 224, 156, 0.8) 42.12%, rgba(225, 201, 201, 0.8) 59.23%, rgba(255, 251, 251, 0.8) 71.82%, rgba(210, 168, 17, 0.8) 81.49%, rgba(235, 235, 235, 0.8) 85.89%)
            .description
                padding: 67px 0 62px 0

            .title
                line-height: 187.5%
                font-weight: 700
                font-size: 32px
                color: var(--text-color)
                transition: all .5s ease
            .value
                line-height: 187.5%
                position: relative
                font-weight: 700
                font-size: 24px
                color: var(--text-color)
                transition: all .5s ease
                &::before
                    position: absolute
                    content: ""
                    height: 1px
                    width: 303px
                    background-color: rgba(0,0,0,0.5)
                    bottom: -5px
                    left: 0
            .term
                line-height: 187.5%
                padding: 21px 0 21px 0
                position: relative
                font-weight: 700
                font-size: 24px
                color: var(--text-color)
                transition: all .5s ease
                &::before
                    position: absolute
                    content: ""
                    height: 1px
                    width: 303px
                    background-color: rgba(0,0,0,0.5)
                    bottom: 0
                    left: 0
                    
            .text
                line-height: 187.5%
                margin-top: 24px
                font-weight: 300
                font-size: 24px
                color: var(--text-color)
                transition: all .5s ease
            .btn
                width: 267px
                height: 52px
                border: 3px solid #08C532
                background-color: rgba(1,1,1,0)
                font-weight: 700
                font-size: 24px
                line-height: 187.5%
                color: #08C532
                border-radius: 10px
                transition: all .3s ease
                &:hover
                    background-color: #08C532
                    color: var(--text-color)
                    transform: scale(1.05)
    .compresion
        &_title
            padding: 45px 0 20px 0
            text-align:  center
            font-weight: 400
            font-size: 32px
            line-height: 187.5%
            color: var(--text-color)
            transition: all .5s ease
        .table
            border-collapse: separate
            width: 100%
            border-radius: 15px
            border-spacing: 0 0
            box-shadow: 0 0 10px #404040
            .pip
                margin: 0 auto
                width: 20px
                height: 20px
                background-color: #08C532
                border-radius: 50% 

            td
                background-color: var(--main-color)
                border: 1px solid #404040
                padding: 10px
                text-align: center
                font-weight: 400
                font-size: 24px
                line-height: 187.5%
                color: var(--text-color)
                transition: all .5s ease
                //box-shadow: 0 0 10px #404040
            tr:nth-child(1)
                td:nth-child(3)
                    color: #000
                    background-color: rgba(231, 124, 64, 0.7)
                td:nth-child(4)
                    color: #000
                    background-color: #D9D9D9
                td:nth-child(5)
                    color: #000
                    background-color: #FFB318
            tr:first-of-type
                td:first-of-type 
                    border-top-left-radius: 15px
            tr:first-of-type
                td:last-of-type 
                    border-top-right-radius: 15px
            
            tr:last-of-type
                td:first-of-type 
                    border-bottom-left-radius: 15px
            
            tr:last-of-type     
                td:last-of-type 
                    border-bottom-right-radius: 15px
            
.Page
    min-height: 715px
    position: relative
    margin: 250px 0 0px 0
    .bookPage
        z-index: 1
        .background
            position: absolute
            top: -250px
            left: 0
            right: 0
            background-size: cover
            background-position: 50%
            background-repeat: no-repeat
            height: 320px
            //background: rgba(0,0,0,.8)
            opacity: 0.8
            z-index: -1
            &:before
                position: absolute
                content: ''
                z-index: 5
                width: 100%
                height: 300px
                //background-color: rgba(0,0,0,.5)
            &:after
                content: ""
                display: block
                height: 150px
                position: absolute
                left: 0
                right: 0
                bottom: -1px
                z-index: 3
                background: -webkit-gradient(linear,left bottom,left top,from(var(--bac-color)),to(transparent))
                background: linear-gradient(0deg,var(--bac-color),transparent)
                
                
            


        .wrapper
            display: flex
            justify-content: space-between
            gap: 10px
        &_right
            img
                width: 220px
                height: 292px
                border-radius: 10px 
        &_dropdown
            position: relative
            font-weight: 400
            font-size: 18px
            line-height: 16px
            color: var(--text-color)
            transition: all .5s ease
            .dropdown_ul
                position: absolute
                border-radius: 3px
                top: 40px
                left: 0
                width: 220px
                background-color: var(--main-color)
                transition: all .2s ease
                &__item
                    padding: 10px 0 10px 15px
                    border-radius: 3px
                    cursor: pointer
                    transition: all .2s ease
                    &:hover
                        background: var(--borderLastUpdate)
                    &_active
                        background-color: var(--sub-color)
                    &_delete
                        color: red

                    
            .mark
                position: relative
                margin-top: 5px 
                display: flex
                justify-content: center
                align-items: center
                width: 220px
                height: 34px    
                border: 2px solid var(--activeGray-color)
                border-radius: 10px
                cursor: pointer
                transition: all .5s ease
                .img
                    position: absolute
                    top: 3px
                    right: 9px
                    width: 24px
                    height: 24px
                    transform: rotate(0deg)
                .active
                    transform: rotate(180deg)
            .read
                background: #03711B
                border: none
            .inPlan
                background-color: #2e51d1
                border: none
            .viewed
                background: #5976a6
                border: none
            .favorite
                background-color: #d52a3d
                border: none
            .abandoned
                background: #8e48b7
                border: none
            

        &_link
            margin-top: 10px
            display: flex
            justify-content: center
            align-items: center
            width: 220px
            height: 34px    
            border: 2px solid var(--activeGray-color)
            border-radius: 10px
            color: var(--activeGray-color)
            font-weight: 400
            font-size: 18px
            line-height: 16px
            transition: all .2s ease
            cursor: pointer
            &:hover
                background-color: var(--activeGray-color)
                color: var(--text-color)
        
        &_left
            width: 916px
            .head
                display: flex
                justify-content: space-between
                background: var(--main-color)
                border-radius: 10px
                padding: 11px 20px 11px 20px
                transition: all .5s ease
            .title
                display: flex
                .name
                    font-weight: 300
                    font-size: 20px
                    line-height: 23px
                    color: var(--text-color)
                    transition: all .5s ease
                .subName
                    position: relative
                    bottom: -3px
                    font-weight: 300
                    font-size: 16px
                    line-height: 19px
                    color: var(--activeGray-color)
                    transition: all .5s ease
            .rating
                    display: flex
                    top: 14px
                    right: 10px
                    img
                        position: relative
                        top:2px
                        width: 20px
                        height: 20px
                    .number
                        margin-left: 5px
                        font-weight: 300
                        font-size: 20px
                        line-height: 27px
                        color: var(--text-color)
                        transition: all .5s ease
                
            .content
                position: relative
                padding: 30px 57px 30px 57px
                margin-top: 10px
                background: var(--main-color)
                border-radius: 10px
                transition: all .5s ease
                .wrapper
                    display: flex
                    justify-content: flex-start
                    gap: 95px
                .block
                    display: flex
                    flex-direction: column
                    gap: 30px
                &_item
                    
                    font-weight: 300
                    font-size: 16px
                    line-height: 19px
                    text-align: justify
                    color: var(--text-color)
                    transition: all .5s ease
                    &::after
                        margin: 35px 57px 0 57px
                        content: ""
                        position: absolute
                        width: 802px
                        height: 1px
                        left: 0
                        background-color:  var(--LineUpdate)
                        transition: all .5s ease
                .description
                    margin-top: 30px
                    font-weight: 300
                    font-size: 16px
                    line-height: 19px
                    text-align: justify
                    color: var(--text-color)
                    transition: all .5s ease
                    line-height: 1.5
.TextInBook
    padding-top: 70px 
    margin: 50px 0 50px 0
    .title
        font-weight: 400
        font-size: 40px
        line-height: 16px
        color: var(--text-color)
        transition: all .5s ease  
    .content
        padding: 40px   
        display: block
        font-size: 20px
        word-break: break-word
        
        .text
            font-weight: 300
            color: var(--text-color)
            margin-bottom: 20px
            line-height: 2.6
            transition: all .5s ease

.Pagination
    position: absolute
    bottom: 340px
    left: 50%
    transform: translateX(-50%)
    font-weight: 300
    font-size: 16px
    width: 1146px
    .block
        // margin: 0 auto
        // width: 1146px
        display: flex
        justify-content: space-between
        transition: all .5s ease
        .wrapper
            display: flex
            gap: 10px
        &_number
            color: var(--text-color)
            border-radius: 10px
            display: flex
            justify-content: center
            align-items: center
            width: 40px
            height: 40px
            background-color: var(--main-color)
            font-size: 20px
            transition: .3s all ease
            border: 1px solid #353535
            
            &:hover
                background-color: var(--activeGray-color)
        .active
            background-color: var(--activeGray-color)
        .arrow
            width: 100px
            font-size: 35px
        .redirect
            width: 150px
            color: var(--text-color)
            font-size: 15px
            font-weight: 300
        .disabled

            //visibility: hidden
            background-color: var(--PaginationDisabled2)
            color: var(--PaginationDisabled)
            pointer-events: none
            

.register
    &_block
        position: relative
        padding: 20px
        width: 600px
        margin: 0 auto
        background: var(--main-color)
        box-shadow: 0 0 10px #404040
        border: 1px solid #404040
        border-radius: 10px
        &_link
            //width: 200px
            text-align: end
            color: var(--activeGray-color)
            transition: all .2s ease
            &:hover
                color: var(--title)
        &_btn
            display: flex
            justify-content: center
            align-items:  center
            margin: 0 auto
            padding: 20px
            width: 200px
            height: 50px
            background-color: #2d73e4
            color: #fff
            border-radius: 3px
        &_btn:disabled
            background-color: var(--activeGray-color) 

        .Min
            position: relative
            top: -10px
            background-color: var(--sub-color)
            color: var(--text-color)
            width: 80px
            height: 25px
            padding: 5px
            border-radius: 10px 

     
    .title
        padding: 20px
        font-weight: 400
        font-size: 25px
        text-align: center
        color: var(--text-color)
    &_form
        margin: 0 auto
        width: 400px
        display: flex
        flex-direction: column
        gap: 20px
        input
            position: relative
            margin: 0 auto
            width: 100%
            height: 50px
            padding-left: 20px
            // background: var(--sub-color)
            background: transparent
            color: var(--text-color)
            transition: all .5s ease
            border: none
            border-bottom: 1px solid var(--activeGray-color) 
            outline: none
        .icon
            height: 22px
            width: 22px
            position: absolute
            top: 13px
            right: 10px
            cursor: pointer 
            z-index: 2
            svg
                width: 22px
                height: 22px
                color: var(--activeGray-color)    
        .passwordForm
            width: 100%
            position: relative
            margin: 0 auto
