@media( max-width: 1800px)
    .slider
        &_book
            &_img
                img
                    width: 160px
                    height: 236px
            &_name
                width: 160px
                font-size: 15px           
            &_subName
                font-size: 12px
    .intro
        &_title
            font-size: 44px


@media( max-width: 1680px)
    .slider
        &_book
            &_img
                img
                    width: 150px
                    height: 216px
            &_name
                width: 150px
                font-size: 13px           
            &_subName
                font-size: 10px
    .lastUpdate
        .book
            .book-item
                width: 760px
                min-height: 330px
                &__left
                    img
                        width: 190px
                        height: 270px
                &__right
                    .right-description
                        width: 480px
                        font-size: 15px
@media( max-width: 1580px)
    .slider
        &_book
            &_img
                img
                    width: 130px
                    height: 190px
            &_name
                width: 130px
                font-size: 13px           
            &_subName
                font-size: 9px
@media( max-width: 1370px)
    .slider
        &_book
            &_img
                img
                    width: 120px
                    height: 170px
            &_name
                width: 130px
                font-size: 13px           
            &_subName
                font-size: 9px